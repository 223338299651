import { gql } from '@apollo/client';

export const getResponse = gql`
  query getResponse($id: uuid!) {
    response: form_response_by_pk(id: $id) {
      id
      asset
      complete
      responses
    }
  }
`;

export const getFormsForUser = gql`
  query getFormsForUser($user_id: uuid!) {
    forms: user_form(where: { user: { _eq: $user_id } }) {
      id
      course
      createdAt: created_at
      updatedAt: updated_at
      responsesByResponses {
        id
        asset
        complete
        responses
      }
      form: formByForm {
        id
        title
        content
        formQuestions(order_by: { order: asc }) {
          id
          order
          properties
          question: questionByQuestion {
            id
            label
            type
            type
            public
            properties
          }
        }
      }
      formByForm {
        id
        title
        content
        formQuestions(order_by: { order: asc }) {
          id
          order
          properties
          question: questionByQuestion {
            id
            label
            type
            type
            public
            properties
          }
        }
      }
    }
  }
`;

export const getFormsForEvent = gql`
  query getFormsForEvent($event_id: uuid!, $user_id: uuid!) {
    user_form(
      where: {
        _and: [{ user: { _eq: $user_id } }, { course: { _eq: $event_id } }]
      }
    ) {
      id
      course
      createdAt: created_at
      updatedAt: updated_at
      member
      responsesByResponses {
        id
        asset
        complete
        responses
        reports {
          id
          reportId: model_report_id
          modelReport {
            studentId: student_id
          }
        }
      }
      form: formByForm {
        id
        title
        content
        formQuestions(order_by: { order: asc }) {
          id
          order
          properties
          question: questionByQuestion {
            id
            label
            archived
            type
            public
            properties
          }
        }
      }
      formByForm {
        id
        title
        content
        formQuestions(order_by: { order: asc }) {
          id
          order
          properties
          question: questionByQuestion {
            id
            label
            type
            public
            properties
          }
        }
      }
    }
  }
`;

export const getEventsByRange = gql`
  query getEventsByRange($start: timestamptz, $end: timestamptz) {
    event(
      where: {
        _and: [
          {
            _or: [
              { _and: [{ start: { _gte: $start } }, { start: { _lt: $end } }] }
              { _and: [{ end: { _gt: $start } }, { end: { _lte: $end } }] }
            ]
          }
          { status: { _neq: "ARCHIVED" } }
        ]
      }
    ) {
      id
      status
      updated_at
      start
      seats
      seat_price
      end
      detail_id
      date
      type
      created_at
      detail {
        created_at
        id
        title
      }
      resource: event_resource {
        id
        name
      }
      sessions {
        id
        date
        start
        end
        seats
        product
        product_description
        price: seat_price
        type
      }
      countStudentRegistrations: registrations_aggregate(
        where: {
          _and: [
            { type: { _eq: "STUDENT" } }
            { status: { _neq: "CANCELLED" } }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      countModelRegistrations: registrations_aggregate(
        where: {
          _and: [
            { type: { _eq: "MODEL" } }
            { status: { _neq: "CANCELLED" } }
            { session_id: { _is_null: false } }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      registrations(where: { _and: { status: { _neq: "CANCELLED" } } }) {
        id
        type
        enrollment_id
        confirmed
        confirmed_by
        confirmer {
          full_name
          email
          given_name
          user_assets {
            asset: assetByAsset {
              id
              url
            }
          }
        }
        model_session_purchase {
          id
          status
          total
          line_items
          employee {
            full_name
            email
            given_name
            user_assets {
              asset: assetByAsset {
                id
                url
              }
            }
          }
        }
        enrollment {
          metadata
          client_data
        }
        createdAt: created_at
        user {
          id
          name: given_name
          surname: family_name
          email
          labels: user_labels(order_by: { created_at: desc }, limit: 2) {
            id
            description
            color
            label {
              id
              name
            }
          }
        }
        session {
          start
          end
        }
      }
      resources: event_resources(
        where: { type: { _in: ["TUTOR", "ASSISTANT"] } }
      ) {
        id
        type
        user {
          id
          name: given_name
          surname: family_name
          avatar: user_assets(limit: 1, order_by: { created_at: desc }) {
            asset: assetByAsset {
              id
              url
            }
          }
        }
      }
    }
  }
`;

export const getEventsCalendar = gql`
  query getEventsCalendar(
    $start: timestamptz!
    $end: timestamptz!
    $status: String!
  ) {
    events: get_events_with_status(
      args: { period_end: $end, period_start: $start, event_status: $status }
      order_by: { start: asc }
    ) {
      id
      type
      detail {
        id
        title
        attributes
      }
      vtct
      vtctSettings
      price: seat_price
      seats
      start
      status
      end
      date
      sell: is_sellable
      title
      resource_id
      resource: event_resource {
        id
        name
      }
      countStudentRegistrations: registrations_aggregate(
        where: {
          _and: [
            { type: { _eq: "STUDENT" } }
            { status: { _neq: "CANCELLED" } }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      countModelRegistrations: registrations_aggregate(
        where: {
          _and: [
            { type: { _eq: "MODEL" } }
            { status: { _neq: "CANCELLED" } }
            { session_id: { _is_null: false } }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      students: registrations(
        where: {
          _and: { status: { _neq: "CANCELLED" }, type: { _eq: "STUDENT" } }
        }
      ) {
        id
        type
        enrollment_id
        user {
          id
          name: given_name
          surname: family_name
          labels: user_labels {
            id
            description
            color
            label {
              id
              name
            }
          }
          email
        }
      }
      models: registrations(
        where: {
          _and: { status: { _neq: "CANCELLED" }, type: { _eq: "MODEL" } }
        }
      ) {
        id
        type
        confirmed
        confirmed_by
        confirmer {
          full_name
          email
          given_name
          user_assets {
            asset: assetByAsset {
              id
              url
            }
          }
        }
        session {
          start
          end
        }
        user {
          id
          name: given_name
          surname: family_name
          labels: user_labels {
            id
            description
            color
            label {
              id
              name
            }
          }
          email
        }
      }
      resources: event_resources(
        where: { type: { _in: ["TUTOR", "ASSISTANT"] } }
      ) {
        id
        type
        user {
          id
          name: given_name
          surname: family_name
          avatar: user_assets(limit: 1, order_by: { created_at: desc }) {
            asset: assetByAsset {
              id
              url
            }
          }
        }
      }
    }
  }
`;

export const getProgrammeEvents = gql`
  query getEvents($where: event_bool_exp) {
    events: event(where: $where, order_by: { start: asc }) {
      id
      detail {
        id
        title
        attributes
      }
      detail_id
      vtct
      vtctSettings
      price: seat_price
      seat_price
      seats
      start
    }
  }
`;

export const getEventsSlim = gql`
  query getEvents($where: event_bool_exp, $limit: Int = 1000) {
    events: event(where: $where, order_by: { start: asc }, limit: $limit) {
      id
      detail {
        id
        title
      }
      price: seat_price
      seat_price
      seats
      start
      status
      end
      detail_id
      eventDetailId: detail_id
      date
      type
      sell: is_sellable
      cohort: cohort_id
      title
      createdAt: created_at
      updatedAt: updated_at
      resource: event_resource {
        id
        name
      }
      countStudentRegistrations: registrations_aggregate(
        where: {
          _and: [
            { type: { _eq: "STUDENT" } }
            { status: { _neq: "CANCELLED" } }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`;

export const getEvents = gql`
  query getEvents($where: event_bool_exp, $limit: Int = 1000) {
    events: event(where: $where, order_by: { start: asc }, limit: $limit) {
      id
      detail {
        id
        title
        attributes
        contentfulId: contentful_id
        createdAt: created_at
        metadata
        updatedAt: updated_at
        standardSeats: standard_seat
        standardPrice: standard_price
      }
      vtct
      vtctSettings
      price: seat_price
      seat_price
      seats
      start
      status
      end
      detail_id
      eventDetailId: detail_id
      date
      type
      sell: is_sellable
      cohort: cohort_id
      title
      createdAt: created_at
      updatedAt: updated_at
      resource: event_resource {
        id
        name
      }
      sessions {
        id
        date
        event_id
        start
        end
        seats
        product
        product_description
        seat_price
        price: seat_price
        type
      }
      countStudentRegistrations: registrations_aggregate(
        where: {
          _and: [
            { type: { _eq: "STUDENT" } }
            { status: { _neq: "CANCELLED" } }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      countModelRegistrations: registrations_aggregate(
        where: {
          _and: [
            { type: { _eq: "MODEL" } }
            { status: { _neq: "CANCELLED" } }
            { session_id: { _is_null: false } }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      students: registrations(
        where: {
          _and: { status: { _neq: "CANCELLED" }, type: { _eq: "STUDENT" } }
        }
      ) {
        id
        createdAt: created_at
        enrollment {
          id
        }
        user {
          id
          name: given_name
          surname: family_name
          email
        }
      }
      models: registrations(
        where: {
          _and: { status: { _neq: "CANCELLED" }, type: { _eq: "MODEL" } }
        }
      ) {
        id
        user_id
        session_id
        createdAt: created_at
        user {
          id
          name: given_name
          surname: family_name
          contact_number
          email
        }
      }
      resources: event_resources(
        where: { type: { _in: ["TUTOR", "ASSISTANT"] } }
      ) {
        id
        type
        user {
          id
          name: given_name
          surname: family_name
          avatar: user_assets(limit: 1, order_by: { created_at: desc }) {
            asset: assetByAsset {
              id
              url
            }
          }
        }
      }
    }
  }
`;

export const getEventById = gql`
  query getEventById($id: uuid!) {
    event: event_by_pk(id: $id) {
      status
      updated_at
      start
      seats
      vtct
      vtctSettings
      seat_price
      end
      detail_id
      date
      type
      created_at
      resource_id
      cohorts {
        id
      }
      video_calls(where: { status: { _eq: "confirmed" } }) {
        id
        created_at
        updated_at
        url
        meet_id
        status
        start
        end
        data
      }
      resource: event_resource {
        id
        name
      }
      detail {
        created_at
        metadata
        attributes
        id
        standard_price
        title
        updated_at
        standard_seat
        programme_id
        programme {
          title
          units {
            id
            title
          }
        }
      }
      id
      countStudentRegistrations: registrations_aggregate(
        where: {
          _and: {
            event_id: { _eq: $id }
            status: { _neq: "CANCELLED" }
            type: { _eq: "STUDENT" }
          }
        }
      ) {
        aggregate {
          count
        }
      }
      studentRegistrations: registrations(
        where: {
          _and: {
            event_id: { _eq: $id }
            status: { _neq: "CANCELLED" }
            type: { _eq: "STUDENT" }
          }
        }
      ) {
        id
        session_id
        status
        type
        updated_at
        user_id
        student_id
        event_id
        created_at
        enrollment_id
        exam_sessions {
          id
          created_at
          updated_at
          meet_id
          status
          start
          end
          data
          session
          member {
            id
            full_name
            email
            given_name
            user_assets {
              asset: assetByAsset {
                id
                url
              }
            }
          }
        }
        enrollment {
          id
          balance
          status
          total
          client_data
          metadata
          deposit
        }
        user {
          created_at
          name: given_name
          surname: family_name
          given_name
          family_name
          email
          id
          contact_number
          cohort_members(where: { event_id: { _eq: $id } }) {
            id
          }
          labels: user_labels {
            id
            description
            color
            label {
              id
              name
            }
          }
          user_forms(order_by: { created_at: desc }) {
            id
            course
            created_at
            responsesByResponses {
              id
              asset
              complete
              responses
            }
            form: formByForm {
              id
              title
              content
              formQuestions(order_by: { order: asc }) {
                id
                order
                properties
                archived
                question: questionByQuestion {
                  id
                  label
                  archived
                  type
                  public
                  properties
                }
              }
            }
            formByForm {
              id
              title
              content
              formQuestions(order_by: { order: asc }) {
                id
                order
                properties
                archived
                question: questionByQuestion {
                  id
                  label
                  type
                  public
                  archived
                  properties
                }
              }
            }
          }
          certificates {
            event_id
            id
            created_at
          }
        }
        invoices {
          id
          invoice_id
          amount
          status
        }
        payments {
          payment {
            created_at
            amount
            discount
            discount_id
            discount_explanation
            method
          }
        }
      }
      countModelRegistrations: registrations_aggregate(
        where: {
          _and: {
            event_id: { _eq: $id }
            status: { _neq: "CANCELLED" }
            _or: [{ type: { _eq: "MODEL" } }, { type: { _eq: "NO_SHOW" } }]
          }
        }
      ) {
        aggregate {
          count
        }
      }
      modelRegistrations: registrations(
        where: {
          _and: {
            event_id: { _eq: $id }
            status: { _neq: "CANCELLED" }
            _or: [{ type: { _eq: "MODEL" } }, { type: { _eq: "NO_SHOW" } }]
          }
        }
      ) {
        id
        session_id
        status
        type
        confirmed
        confirmed_by
        follow_up
        updated_at
        user_id
        event_id
        created_at
        student_id
        confirmer {
          full_name
          email
          given_name
          user_assets {
            asset: assetByAsset {
              id
              url
            }
          }
        }
        model_session_purchase {
          id
          status
          total
          line_items
          employee {
            full_name
            email
            given_name
            user_assets {
              asset: assetByAsset {
                id
                url
              }
            }
          }
        }
        modelReports {
          status
          id
          student_id
          student {
            id
            name: given_name
            surname: family_name
          }
          created_at
          updated_at
          assets {
            id
            asset {
              props
              url
            }
          }
        }
        user {
          created_at
          given_name
          family_name
          name: given_name
          surname: family_name
          email
          contact_number
          id
          labels: user_labels {
            id
            description
            color
            label {
              id
              name
            }
          }
          user_forms {
            id
            course
            created_at
            responsesByResponses {
              id
              asset
              complete
              client_complete
              responses
            }
            form: formByForm {
              id
              title
              content
              formQuestions(order_by: { order: asc }) {
                id
                order
                properties
                archived
                question: questionByQuestion {
                  id
                  label
                  type
                  public
                  archived
                  properties
                }
              }
            }
            formByForm {
              id
              title
              content
              formQuestions(order_by: { order: asc }) {
                id
                order
                properties
                archived
                question: questionByQuestion {
                  id
                  label
                  type
                  archived
                  public
                  properties
                }
              }
            }
          }
        }
        payments {
          payment {
            created_at
            amount
            discount
            discount_id
            discount_explanation
            method
          }
        }
      }
      sessions(order_by: { start: asc }) {
        updated_at
        seat_price
        start
        seats
        type
        product
        product_description
        id
        end
        auto
        date
        created_at
        event_id
        registration
        updated_by
        created_by
        updatedBy {
          id
          created_at
          name: given_name
          surname: family_name
        }
        createdBy {
          id
          created_at
          name: given_name
          surname: family_name
        }
        registrations_aggregate(where: { type: { _neq: "CANCELLED" } }) {
          aggregate {
            count
          }
        }
      }
      event_resources(order_by: { created_at: asc }) {
        user_id
        type
        id
        event_id
        user {
          given_name
          family_name
          email
          user_assets(
            limit: 1
            order_by: { assetByAsset: { createdAt: desc } }
          ) {
            assetByAsset {
              url
            }
          }
        }
      }
      event_notes {
        data: noteByNote {
          created_at
          content
          user
          title
          updated_at
          id
        }
        note
        id
        created_at
        event
        updated_at
      }
    }
  }
`;

export const getAllEventsById = gql`
  query getEventById($id: [uuid!]) {
    event(where: { id: { _in: $id } }) {
      id
      created_at
      updated_at
      start
      end
      seats
      seat_price
      status
      type
      detail {
        title
      }
    }
  }
`;

export const insertEvent = gql`
  mutation insertEvent($data: event_insert_input!) {
    insert_event_one(object: $data) {
      id
    }
  }
`;

export const insertEvents = gql`
  mutation insertEvents($data: [event_insert_input!]!) {
    insert_event(objects: $data) {
      returning {
        id
      }
    }
  }
`;

export const getAllEventDetailsExcludingPackages = gql`
  query getAllEventDetails {
    event_detail(
      where: {
        _and: [{ archived: { _neq: true } }, { is_package: { _eq: false } }]
      }
      order_by: { title: asc }
    ) {
      id
      title
      standard_price
      standard_seat
      updated_at
      created_at
      contentful_id
      metadata
      type
      archived
      wasPrice: was_price
      is_package
      events(
        where: { status: { _neq: "ARCHIVED" } }
        order_by: { start: asc }
      ) {
        id
        date
        start
        end
        seats
        seat_price
        status
        type
        detail_id
        detail {
          title
        }
        event_resources {
          id
          user_id
          type
          user {
            given_name
          }
        }
      }
    }
  }
`;

export const insertMaterial = gql`
  mutation insertMaterial($data: [event_material_insert_input!]!) {
    insert_event_material(objects: $data) {
      affected_rows
    }
  }
`;

export const deleteMaterials = gql`
  mutation deleteMaterials($ids: [uuid!]!) {
    delete_event_material(where: { id: { _in: $ids } }) {
      affected_rows
    }
  }
`;

export const deleteMaterial = gql`
  mutation deleteMaterial($eventId: uuid!) {
    delete_event_material(where: { event_detail_id: { _eq: $eventId } }) {
      affected_rows
    }
  }
`;

export const getAllEventDetails = gql`
  query getAllEventDetails {
    event_detail(
      where: { archived: { _neq: true } }
      order_by: { title: asc, is_package: desc }
    ) {
      id
      title
      standard_price
      standard_seat
      assets
      updated_at
      created_at
      contentful_id
      metadata
      type
      archived
      elearning_course_id
      wasPrice: was_price
      attributes
      is_package
      materials {
        id
        created_at
        source
        attributes
        name
      }
      template {
        id
        created_at
        updated_at
        template {
          id
          created_at
          updated_at
          label
          groups {
            id
            created_at
            updated_at
            label
            sections {
              id
              resource_id
              created_at
              updated_at
              label
              sessions
            }
          }
        }
      }
    }
  }
`;

export const getAllEventDetailsSlim = gql`
  query getAllEventDetails {
    event_detail(
      where: {
        _and: [{ archived: { _neq: true } }, { is_package: { _eq: false } }]
      }
      order_by: { title: asc, is_package: desc }
    ) {
      id
      title
    }
  }
`;

export const insertEventSession = gql`
  mutation insertEventSession($data: event_session_insert_input!) {
    insert_event_session_one(object: $data) {
      id
      start
      end
    }
  }
`;
export const insertEventSessions = gql`
  mutation insertEventSession($data: [event_session_insert_input!]!) {
    insert_event_session(objects: $data) {
      affected_rows
    }
  }
`;

export const updateEvent = gql`
  mutation updateEvent($id: uuid!, $data: event_set_input!) {
    update_event_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
      date
      start
      end
      detail {
        title
      }
    }
  }
`;

export const updateEventSession = gql`
  mutation updateEventSession($id: uuid!, $data: event_session_set_input!) {
    update_event_session_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`;

export const deleteEventSession = gql`
  mutation deleteEventSession($id: uuid!) {
    delete_event_session_by_pk(id: $id) {
      id
      date
      start
      end
    }
  }
`;

export const modelSessionsInFutureByEvent = gql`
  query modelSessionsInFutureByEvent($now: timestamptz, $detailId: uuid!) {
    event(
      where: {
        _and: [{ detail_id: { _eq: $detailId } }, { start: { _gte: $now } }]
      }
    ) {
      sessions(
        order_by: { start: asc }
        where: {
          _and: [
            { event: { status: { _neq: "ARCHIVED" } } }
            { seats: { _gt: 0 } }
          ]
        }
      ) {
        id
        created_at
        updated_at
        type
        event_id
        product
        product_description
        event {
          id
          start
          end
          detail {
            id
            title
            type
          }
          event_resources {
            id
            type
            user {
              given_name
            }
          }
        }
        date
        start
        end
        seats
        seat_price
        start
        totalSeats: seats
        seatsFilled: registrations_aggregate(
          where: {
            _and: { status: { _neq: "CANCELLED" }, type: { _eq: "MODEL" } }
          }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  }
`;

export const getAllEventSessionsById = gql`
  query getAllEventSessionsById($id: [uuid!]!) {
    eventSession: event_session(where: { id: { _in: $id } }) {
      id
      created_at
      updated_at
      start
      end
      seats
      seat_price
      registrations {
        id
        created_at
        status
        type
        user {
          id
          name: given_name
          surname: family_name
        }
        event {
          id
          detail {
            title
          }
        }
      }
    }
  }
`;

export const insertEventResource = gql`
  mutation insertEventResource($data: event_resource_insert_input!) {
    insert_event_resource_one(object: $data) {
      id
    }
  }
`;
export const insertEventResources = gql`
  mutation insertEventResource($data: [event_resource_insert_input!]!) {
    insert_event_resource(objects: $data) {
      affected_rows
    }
  }
`;

export const deleteEventResources = gql`
  mutation deleteEventResource($where: event_resource_bool_exp!) {
    delete_event_resource(where: $where) {
      affected_rows
    }
  }
`;

export const deleteEventResource = gql`
  mutation deleteEventResource($id: uuid!) {
    delete_event_resource_by_pk(id: $id) {
      id
    }
  }
`;

export const addEventNote = gql`
  mutation eventNote($data: event_note_insert_input!) {
    insert_event_note_one(object: $data) {
      id
      note: noteByNote {
        id
        content
        createdAt: created_at
        updatedAt: updated_at
        user
      }
    }
  }
`;

export const deleteNote = gql`
  mutation deleteNote($id: uuid!) {
    delete_note_by_pk(id: $id) {
      id
    }
  }
`;

export const insertEventDetail = gql`
  mutation insertEventDetail($data: event_detail_insert_input!) {
    insert_event_detail_one(object: $data) {
      id
    }
  }
`;

export const updateEventDetail = gql`
  mutation updateEventDetail($id: uuid!, $data: event_detail_set_input!) {
    update_event_detail_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`;

// export const updateAppointmentNote = gql`
// mutation UpdateNote($id: uuid!, $content: String!) {
//   update_note_by_pk(
//     pk_columns: { id: $id }
//     _set: {
//       content: $content
//     }
//   ) {
//     id
//     title
//     content
//     user
//     updatedAt: updated_at
//   }
// }
// `

export const getFutureEvents = gql`
  query getFutureEvents($date: timestamptz) {
    event(where: { _and: { date: { _gte: $date } } }) {
      status
      updated_at
      start
      seats
      seat_price
      end
      detail_id
      date
      type
      created_at
      detail {
        created_at
        metadata
        id
        standard_price
        title
        updated_at
        standard_seat
      }
    }
  }
`;

export const getResourceById = gql`
  query getResourceById($id: uuid!) {
    resource_by_pk(id: $id) {
      id
      created_at
      updated_at
      name
    }
  }
`;

export const getEventStatistics = gql`
  query EvStats($start: timestamptz, $end: timestamptz) {
    get_event_statistics(args: { start_date: $start, end_date: $end }) {
      seat_utilization_percentage
      students_booked_this_month
      total_empty_courses
      total_events_scheduled
      total_full_events
      total_registered_seats
      total_registrations
      total_seats_available
      total_value_of_open_seats
    }
  }
`;

export const insertExamSession = gql`
  mutation insertExamSession($data: exam_session_insert_input!) {
    session: insert_exam_session_one(object: $data) {
      id
    }
  }
`;

export const updateExamSessions = gql`
  mutation updateExamSession(
    $where: exam_session_bool_exp!
    $data: exam_session_set_input!
  ) {
    session: update_exam_session(where: $where, _set: $data) {
      affected_rows
    }
  }
`;
export const updateExamSession = gql`
  mutation updateExamSession($id: uuid!, $data: exam_session_set_input!) {
    session: update_exam_session_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`;

export const getExams = gql`
  query getExams(
    $where: exam_session_bool_exp
    $order_by: [exam_session_order_by!]
    $limit: Int
    $offset: Int
  ) {
    examSessions: exam_session(
      where: $where
      order_by: $order_by
      limit: $limit
      offset: $offset
    ) {
      id
      created_at
      updated_at
      updated_by
      created_by
      event_id
      registration_id
      data
      meet_id
      start
      end
      status
      member_id
      url
      session
      event {
        id
        start
        end
        detail {
          title
        }
      }
      registration {
        id
        status
        user {
          id
          name: given_name
          surname: family_name
          full_name
          email
        }
      }
      updatedBy {
        id
        name: given_name
        memberAvatar: user_assets {
          asset: assetByAsset {
            id
            url
          }
        }
      }
      createdBy {
        id
        name: given_name
        memberAvatar: user_assets {
          asset: assetByAsset {
            id
            url
          }
        }
      }
      member {
        id
        name: given_name
        memberAvatar: user_assets {
          asset: assetByAsset {
            id
            url
          }
        }
      }
    }
  }
`;
