import { gql } from '@apollo/client';

export const getCalls = gql`
  subscription GetCalls {
    call(limit: 500, order_by: { created_at: desc }) {
      id
      due
      callSid: call_sid
      callType: call_type
      referencedCallId: referenced_call_id
      organization
      status
      payload
      user_id
      viewed
      voicemail
      type
      employee {
        name: given_name
      }
      createdAt: created_at
      updatedAt: updated_at
      contacts_aggregate {
        aggregate {
          count
        }
      }
      notes_aggregate {
        aggregate {
          count
        }
      }
      user {
        email
        id
        name: given_name
        surname: family_name
        phoneNumber: contact_number
        labels: user_labels(order_by: { created_at: desc }, limit: 1) {
          id
          description
          color
          label {
            id
            name
          }
        }
      }
    }
  }
`;

export const getCallsForIncoming = gql`
  query GetCalls($start: timestamptz!, $end: timestamptz!) {
    call(
      where: {
        _and: [{ created_at: { _gte: $start } }, { created_at: { _lte: $end } }]
      }
      limit: 200
      order_by: { created_at: desc }
    ) {
      id
      due
      organization
      status
      payload
      user_id
      viewed
      type
      employee {
        name: given_name
      }
      createdAt: created_at
      updatedAt: updated_at
      contacts_aggregate {
        aggregate {
          count
        }
      }
      notes_aggregate {
        aggregate {
          count
        }
      }
      user {
        email
        id
        name: given_name
        surname: family_name
        phoneNumber: contact_number
        labels: user_labels(order_by: { created_at: desc }, limit: 1) {
          id
          description
          color
          label {
            id
            name
          }
        }
      }
    }
  }
`;

export const getCallById = gql`
  query getCallById($id: uuid!) {
    call: call_by_pk(id: $id) {
      id
      due
      organization
      status
      payload
      user_id
      viewed
      voicemail
      type
      employee {
        name: given_name
      }
      createdAt: created_at
      updatedAt: updated_at
      user {
        email
        id
        name: given_name
        surname: family_name
        phoneNumber: contact_number
        user_labels {
          id
          label {
            name
          }
        }
      }
      notes_aggregate {
        aggregate {
          count
        }
      }
      notes {
        id
        data: noteByNote {
          id
          content
          createdAt: created_at
          updatedAt: updated_at
          user
        }
      }
      messages: contacts(order_by: { created_at: desc }) {
        id
        message
        subject
        type
        source
        createdAt: created_at
        updatedAt: updated_at
        user {
          id
          name: given_name
        }
      }
      contacts_aggregate {
        aggregate {
          count
        }
      }
      conversions: conversions {
        id
        value
        createdAt: created_at
        user: user {
          id
          name: given_name
          surname: family_name
        }
        eventByEvent {
          detail {
            title
          }
        }
      }
      conversions_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export const getAllCallsById = gql`
  query getAllCallsById($id: [uuid!]!) {
    call(where: { id: { _in: $id } }) {
      id
      due
      organization
      status
      payload
      user_id
      viewed
      type
      employee {
        name: given_name
        surname: family_name
      }
      createdAt: created_at
      updatedAt: updated_at
      user {
        email
        id
        name: given_name
        surname: family_name
        phoneNumber: contact_number
      }
      notes_aggregate {
        aggregate {
          count
        }
      }
      notes {
        id
        data: noteByNote {
          id
          content
          createdAt: created_at
          updatedAt: updated_at
          user
        }
      }
      messages: contacts(order_by: { created_at: desc }) {
        id
        message
        subject
        type
        source
        createdAt: created_at
        updatedAt: updated_at
        user {
          id
          name: given_name
        }
      }
      contacts_aggregate {
        aggregate {
          count
        }
      }
      conversions: conversions {
        id
        value
        createdAt: created_at
        user: user {
          id
          name: given_name
          surname: family_name
        }
        eventByEvent {
          detail {
            title
          }
        }
      }
      conversions_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export const updateCallById = gql`
  mutation updateCallById($id: uuid!, $data: call_set_input!) {
    update_call_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`;

export const updateCall = gql`
  mutation updateCall($id: uuid!, $status: String!) {
    update_call_by_pk(pk_columns: { id: $id }, _set: { status: $status }) {
      id
    }
  }
`;

export const addCallConversion = gql`
  mutation CallConversion($data: call_conversion_insert_input!) {
    insert_call_conversion_one(object: $data) {
      value
      event
      call
    }
  }
`;

export const addCallNote = gql`
  mutation CallNote($data: call_note_insert_input!) {
    insert_call_note_one(object: $data) {
      id
      noteByNote {
        id
        content
        createdAt: created_at
        updatedAt: updated_at
        user
      }
    }
  }
`;

export const addCallContact = gql`
  mutation CallContact($data: call_contact_insert_input!) {
    insert_call_contact_one(object: $data) {
      message
      subject
      call
      type
      source
    }
  }
`;

export const createCall = gql`
  mutation CreateCall($data: call_insert_input!) {
    insert_call_one(object: $data) {
      id
      payload
      type
      employee_id
      user {
        id
        given_name
        family_name
      }
    }
  }
`;

export const getAllCallSessions = gql`
  subscription getAllCallSessions {
    call_session(
      order_by: { status: asc }
      where: { employee: { member: { active: { _eq: true } } } }
    ) {
      id
      status
      call_id
      options
      call {
        id
        payload
        user {
          id
          given_name
          family_name
        }
      }
      employee {
        id
        given_name
        family_name
        user_assets(where: { assetByAsset: { type: { _eq: AVATAR } } }) {
          asset: assetByAsset {
            id
            type
            url
          }
        }
      }
    }
  }
`;

export const insertEnrollmentCall = gql`
  mutation insertEnrollmentCall($data: enrollment_call_insert_input!) {
    insert_enrollment_call_one(object: $data) {
      id
    }
  }
`;

export const updateCallSession = gql`
  mutation updateCallSession($employee: uuid!, $data: call_session_set_input!) {
    update_call_session(
      where: { employee_id: { _eq: $employee } }
      _set: $data
    ) {
      returning {
        id
        status
        call_id
      }
    }
  }
`;

export const getCallSession = gql`
  query getCallSession($employee: uuid!) {
    call_session(where: { employee_id: { _eq: $employee } }, limit: 1) {
      id
      status
    }
  }
`;

export const insertCallSession = gql`
  mutation insertCallSession($data: call_session_insert_input!) {
    insert_call_session_one(object: $data) {
      id
    }
  }
`;

export const getCallCues = gql`
  query getCallCues {
    cues: call_cue {
      id
      title
      description
      createdAt: created_at
      updatedAt: updated_at
    }
  }
`;

export const callStats = gql`
  query CallStats($start: date!, $end: date!) {
    stats: call_statistics(args: { period_start: $start, period_end: $end }) {
      tid
      id
      name
      type
      created_at
      count
      missed
    }
  }
`;
