import { gql } from '@apollo/client';

import { Invoice, Payment, Refund } from './fragments';

type DiscountCode = {
  id: string;
  code: string;
  value: number;
  type: string;
};

export type Payment = {
  id: string;
  amount: number;
  discount: number;
  discountExplanation: string | null;
  discountCode: DiscountCode | null;
  employee: string;
  transactionId: string;
  method: string;
  user: {
    id: string;
    name: string;
    surname: string;
  };
  paymentItems: {
    id: string;
    amount: number;
    service: {
      id: string;
      title: string;
    };
    productSale: {
      id: string;
      product: {
        id: string;
        title: string;
      };
    };
  }[];
  metadata: { [key: string]: string };
  appointment: {
    data: {
      id: string;
    };
  }[];
  createdAt: string;
  updatedAt: string;
};

export const getPayments = gql`
  query Payments($start: timestamptz!, $end: timestamptz!) {
    aggregate: payment_aggregate(order_by: { completed_at: desc }) {
      payments: aggregate {
        count
      }
    }
    payment(
      order_by: { completed_at: desc }
      where: {
        status: { _eq: "complete" }
        _and: { created_at: { _gte: $start, _lte: $end } }
      }
    ) {
      id
      amount
      discount
      completed_at
      discountExplanation: discount_explanation
      # discountCode: discount_code {
      #   id
      #   code
      #   value
      #   type
      # }
      transactionId: transaction_id
      employee
      metadata
      method
      # appointment: appointment_payments {
      #   data: appointmentByAppointment {
      #     id
      #   }
      # }
      user: userByUser {
        id
        name: given_name
        surname: family_name
      }
      # paymentItems {
      #   id
      #   amount
      #   service {
      #     id
      #     title
      #   }
      #   productSale {
      #     id
      #     product {
      #       id
      #       title
      #     }
      #   }
      # }
      createdAt: created_at
      updatedAt: updated_at
    }
  }
`;

export const getAllPaymentsById = gql`
  query GetAllPaymentsById($id: [uuid!]!) {
    payment(where: { id: { _in: $id } }) {
      id
      amount
      discount
      currency
      transactionId: transaction_id
      method
      status
      createdAt: created_at
      updatedAt: updated_at
      discountExplanation: discount_explanation
      metadata
      user: userByUser {
        id
        name: given_name
        surname: family_name
      }
      employee: userByEmployee {
        id
        name: given_name
        surname: family_name
      }
      enrollment {
        id
      }
    }
  }
`;

export const insertPayment = gql`
  mutation insertPayment($data: payment_insert_input!) {
    insert_payment_one(object: $data) {
      amount
      id
      created_at
      employee
      invoice_id
      method
      status
      transaction_id
      employee
      user
      updated_at
    }
  }
`;

export const registrationPayment = gql`
  mutation RegistrationPayment($data: registration_payment_insert_input!) {
    insert_registration_payment_one(object: $data) {
      id
    }
  }
`;

export const createInvoice = gql`
  mutation CreateInvoice($data: CreateInvoice!) {
    createInvoice(data: $data) {
      id
    }
  }
`;

export const getInvoice = gql`
  query GetInvoice($id: String!) {
    getInvoice(id: $id) {
      id
      due
      charge
      dueDate
      paid
      remaining
      url
      datePaid
      invoiceUrl
      status
      created
    }
  }
`;

export const voidInvoice = gql`
  query voidInvoice($id: String!) {
    voidInvoice(id: $id) {
      id
      status
    }
  }
`;

export const getCommissions = gql`
  query getCommissions($where: commission_bool_exp!) {
    commission(where: $where, order_by: { created_at: desc }) {
      id
      createdAt: created_at
      updatedAt: updated_at
      amount
      enrollment_id
      rate
      fee
      vat
      payment_id
      enrollment {
        payments {
          payment {
            status
            amount
          }
        }
      }
      employee: user {
        id
        name: given_name
        surname: family_name
      }
    }
  }
`;

export const getSales = gql`
  query GetSales($where: payment_bool_exp) {
    payment(order_by: { created_at: desc }, where: $where) {
      id
      amount
      currency
      discount
      discount_explanation
      employee
      enrollment_id
      status
      transaction_id
      week
      month
      createdAt: created_at
      updatedAt: updated_at
      customer: userByUser {
        id
        name: given_name
        surname: family_name
      }
    }
  }
`;

export const getRefunds = gql`
  ${Refund}
  ${Payment}
  ${Invoice}
  query GetRefunds {
    refunds: refund(order_by: { created_at: desc }) {
      ...Refund
      payment {
        ...Payment
      }
      invoice {
        ...Invoice
      }
    }
  }
`;

export const getRefundsForUser = gql`
  ${Refund}
  query getRefundsForUser($id: uuid!) {
    refunds: refund(
      order_by: { created_at: desc }
      where: { payment: { user: { _eq: $id } } }
    ) {
      ...Refund
    }
  }
`;

export const insertRefund = gql`
  ${Refund}
  mutation insertRefund($data: refund_insert_input!) {
    insert_refund_one(object: $data) {
      ...Refund
    }
  }
`;

export const updateRefund = gql`
  ${Refund}
  mutation UpdateRefund($id: uuid!, $data: refund_set_input) {
    update_refund_by_pk(pk_columns: { id: $id }, _set: $data) {
      ...Refund
    }
  }
`;

export const updatePayment = gql`
  mutation updatePayment($id: uuid!, $data: payment_set_input) {
    update_payment_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`;

export const updateInvoice = gql`
  mutation updateInvoice($id: uuid!, $data: invoice_set_input) {
    update_invoice_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`;

export const removeRefund = gql`
  mutation DeleteRefund($id: uuid!) {
    delete_refund_by_pk(id: $id) {
      id
    }
  }
`;
